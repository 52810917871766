import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import FoodList from "./home/FoodList";
import WiserLocker from "./home/WiserLocker";
import Intro from "./home/Intro";
import Footer from "./Footer";

const HomePage: React.FC = () => {
  const [slideHeight, setSlideHeight] = useState("auto");
  const slideRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (slideRef.current) {
        const slides = slideRef.current.querySelectorAll(".swiper-slide");
        const heights = Array.from(slides).map(
          (slide) => (slide as HTMLElement).offsetHeight
        );
        const maxHeight = Math.max(...heights);
        setSlideHeight(`${maxHeight}px`);
      }
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Initial height setting
    handleResize();

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col flex-grow">
      <Swiper
        cssMode={true}
        navigation={true}
        mousewheel={true}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="swiper"
      >
        <SwiperSlide className="swiper-slide" style={{ height: slideHeight }}>
          <FoodList />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide" style={{ height: slideHeight }}>
          <WiserLocker />
        </SwiperSlide>
      </Swiper>
      <div className="w-full h-full">
        <Intro />
      </div>
      <div className="bg-black">
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
